<template>
  <div
    class="show kt-subheader__wrapper"
    style="display: flex; align-items: center"
  >
    <span class="text-muted mr-1 d-none d-md-block"> Period: </span>

    <VueCtkDateTimePicker
      input-size="sm"
      label="Select date"
      formatted="ll"
      format="YYYY-MM-DD"
      v-model="dateRangeStr"
      color="#FF961D"
      :maxDate="getMaxDate"
      :minDate="getMinDate"
      :shortcut="period"
      :range="true"
      :right="right"
      :locale="activeLanguage"
      @input="change"
    >
      <button
        ref="dateLabel"
        type="button"
        style="white-space: nowrap"
        class="btn btn-secondary border-0 bg-light text-primary font-weight-bold"
      ></button>
    </VueCtkDateTimePicker>
  </div>
</template>

<script>
import { SET_FILTERS } from "@/store/stats.module";
import i18nService from "@/common/i18n.service.js";

// https://chronotruck.github.io/vue-ctk-date-time-picker/
// https://github.com/chronotruck/vue-ctk-date-time-picker/

export default {
  props: {
    period: {
      type: String,
      required: false,
      default: "last7Days"
    },
    right: {
      type: Boolean,
      required: false,
      default: true
    },
    reset: {
      type: Boolean,
      required: false,
      default: false
    },
    maxDate: {
      type: String,
      required: false,
      default: ""
    },
    minDate: {
      type: String,
      required: false,
      default: ""
    }
  },

  data() {
    return {
      dateRangeStr: ""
    };
  },

  computed: {
    getMaxDate() {
      if (this.maxDate === "") {
        return new Date().toString();
      }
      return this.maxDate;
    },

    getMinDate() {
      if (this.minDate === "") {
        return "-";
      }
      return this.minDate;
    },

    activeLanguage() {
      return i18nService.getActiveLanguage();
    }
  },

  methods: {
    change({ start, end }) {
      if (start && end) {
        this.$store.dispatch(SET_FILTERS, {
          dateRange: [start, end]
        });

        this.$emit("datepickerChange", {
          dateRange: [start, end]
        });
      }
    }
  },

  watch: {
    reset() {
      if (this.reset) {
        this.$refs.dateLabel.innerText = "Select date";
        this.dateRangeStr = "";
        this.$emit("datepickerChange", {
          dateRange: []
        });
      }
    }
  }
};
</script>
