<script>
import { Line } from "vue-chartjs";
import { convertSecondesToUnity, getDisplayUnity } from "@/common/functions";

export default {
  extends: Line,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    isTimeChart: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    normalizeTime(data, unity) {
      let a = data.map(value => {
        return convertSecondesToUnity(value, unity);
      });
      return a;
    },
    generateChart() {
      let graphData = { ...this.chartdata };
      let options = { ...this.options };
      if (this.isTimeChart) {
        let unity = getDisplayUnity(
          Math.max(...this.chartdata.datasets[0].data)
        );
        graphData.datasets[0].data = this.normalizeTime(
          graphData.datasets[0].data,
          unity
        );

        options = {
          ...options,
          ...{
            scales: {
              ...options.scales,
              ...{
                yAxes: [
                  {
                    ...options.scales.yAxes[0],
                    ...{
                      scaleLabel: {
                        display: true,
                        labelString: unity
                      }
                    }
                  }
                ]
              }
            }
          }
        };
      }
      this.renderChart(graphData, options);
      const HTML = this.$data._chart.generateLegend();
      this.$emit("generated", HTML);
    }
  },
  mounted() {
    this.generateChart();
  },
  watch: {
    chartdata: function() {
      this.$data._chart.destroy();
      this.generateChart();
    }
  }
};
</script>
