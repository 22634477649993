<script>
import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    isTimeChart: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: null
    }
  },
  methods: {
    generateChart() {
      let graphData = { ...this.chartdata };
      let options = { ...this.options };

      this.renderChart(graphData, options);
      const HTML = this.$data._chart.generateLegend();
      this.$emit("generated", HTML);
    }
  },
  mounted() {
    this.generateChart();
  },
  watch: {
    chartdata: function() {
      this.$data._chart.destroy();
      this.generateChart();
    }
  }
};
</script>
