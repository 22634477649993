
export const STATUS_CAMPAIGN = {
  draft: "label-light-info",
  pending : "label-light-warning",
  ready: "label-light-warning",
  running: "label-light-success",
  paused: "label-light-warning",
  finished: "label-light-grey",
}

export const getColorPercentText = (percent) => {
  let color = "text-info";

  if (percent <= 20) {
    color = "text-danger";
  } else if (percent <= 40) {
    color = "text-warning";
  } else if (percent <= 60) {
    color = "text-info";
  } else if (percent > 60) {
    color = "text-success";
  }

  return color;
} 

export const stringifyJson = (json) => {
  return JSON.stringify(json, undefined, 4)
}