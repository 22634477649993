import { get, post, update, remove } from "@/api/getApi1.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";
import { get as getV2, post as postV2 } from "@/api/getApi2.0";

export const getCampaign = (axios, id, filters = {}) =>
  get(
    {
      path: `${ENTITY_NAME_IN_URI.CAMPAIGNS.path}/${id}`
    },
    filters,
    axios
  );

export const getCampaignV2 = (campaignId, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path,
      ressources: [
        {
          name: campaignId
        }
      ]
    },
    {},
    axios
  );

export const getCampaigns = (axios, filters = {}) =>
  get(
    {
      path: `${ENTITY_NAME_IN_URI.CAMPAIGNS.path}`
    },
    filters,
    axios
  );

export const getCampaignsV2 = (axios, filters) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.CAMPAIGNS_ORDERS.path
    },
    filters,
    axios
  );

export const getCampaignsV2Stats = (payload, axios) =>
  postV2(
    {
      path: ENTITY_NAME_IN_URI_V2.STATS_SUMMARY.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.STATS_SUMMARY.name,
          value: ENTITY_NAME_IN_URI_V2.STATS_SUMMARY.value
        }
      ]
    },
    {},
    payload,
    axios
  );

export const getCampaignsUsersNotifications = (campaignId, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.NOTIFICATIONS.path,
      ressources: [
        {
          name: campaignId
        },
        {
          name: ENTITY_NAME_IN_URI_V2.CAMPAIGNS.NOTIFICATIONS.name
        }
      ]
    },
    {},
    axios
  );

export const createCampaign = (axios, data) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.CAMPAIGNS.path
    },
    {},
    data,
    axios
  );

export const updateCampaign = (axios, data, id) =>
  update(
    {
      path: `${ENTITY_NAME_IN_URI.CAMPAIGNS.path}/${id}`
    },
    data,
    axios
  );

export const deleteCampaign = (axios, data, id) =>
  remove(
    {
      path: `${ENTITY_NAME_IN_URI.CAMPAIGNS.path}/${id}`
    },
    data,
    axios
  );

export const statusCampaign = (axios, id, data) =>
  update(
    {
      path: `${ENTITY_NAME_IN_URI.CAMPAIGNS.path}/${id}/status`
    },
    data,
    axios
  );

export const getLogsV2 = (axios, id, filters) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.LOGS.path
    },
    filters,
    axios
  );
