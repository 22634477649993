<template>
  <div v-bind:class="cardSize" class="card-pi-stats">
    <div class="border-0" :class="{ card: visibleCard }">
      <div
        class="card-body align-middle px-4 pt-0"
        :class="{
          'd-flex align-items-center justify-content-center pt-5 pb-5': loading
        }"
      >
        <div
          class="spinner-border text-light align-middle text-dark spinner-voxeus-color"
          role="status"
          v-if="loading"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <slot v-if="!loading"></slot>
      </div>
    </div>
  </div>
</template>

<style>
.spinner-voxeus-color {
  color: #ff961d !important;
}
</style>

<script>
export default {
  name: "AsynCardContainer",
  props: {
    loading: {
      type: Boolean
    },
    sizes: {
      type: Object
    },
    visibleCard: {
      type: Boolean,
      required: false
    }
  },

  components: {},
  methods: {},
  computed: {
    cardSize() {
      let elementClass = "";
      Object.entries(this.sizes).forEach(([type, value]) => {
        if (type === "default") {
          elementClass += "col-" + value + " ";
        } else {
          elementClass += "col-" + type + "-" + value + " ";
        }
      });
      return elementClass;
    }
  }
};
</script>
