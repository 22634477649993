<template>
  <AsynCardContainer
    v-bind:sizes="sizes"
    v-bind:loading="chart.loading"
    :visibleCard="visibleCard"
  >
    <div class="card-header-custom pt-4">
      <h5 class="card-title mb-0 font-weight-bold">{{ chart.title }}</h5>
      <div
        v-if="chart.data.datasets.length > 1"
        class="legend-con"
        v-html="chart.legends"
      ></div>
    </div>
    <BarChart
      v-if="!chart.loading && chart.type === 'bar'"
      :width="300"
      :height="320"
      style="position: relative"
      :chartdata="chart.data"
      :isTimeChart="chart.isTimeChart"
      :options="{ ...options, ...customOptions }"
      @generated="setLegend"
    />
    <AreaChart
      v-if="!chart.loading && chart.type === 'area'"
      :width="300"
      :height="320"
      style="position: relative"
      :chartdata="chart.data"
      v-bind:isTimeChart="chart.isTimeChart"
      :options="{ ...areaOptions, ...customOptions }"
      @generated="setLegend"
    />
    <DoughnutChart
      v-if="!chart.loading && chart.type === 'doughnut'"
      :width="300"
      :height="320"
      style="position: relative"
      :chartdata="chart.data"
      v-bind:isTimeChart="chart.isTimeChart"
      :options="{ ...areaOptions, ...customOptions }"
      @generated="setLegend"
    />
  </AsynCardContainer>
</template>
<style>
.card-header-custom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.legend-con ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.legend-con ul li {
  display: flex;
  margin-right: 12px;
  align-items: center;
}

.legend-con ul li span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}
</style>
<script>
import AsynCardContainer from "./AsynCardContainer";
import BarChart from "@/views/partials/content/charts/BarChart";
import AreaChart from "@/views/partials/content/charts/AreaChart";
import DoughnutChart from "@/views/partials/content/charts/Doughnut";
export default {
  name: "ChartCard",
  props: {
    chart: {
      type: Object,
      default: () => {
        return {};
      }
    },
    customOptions: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sizes: {
      type: Object,
      default: () => {
        return {};
      }
    },
    visibleCard: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        tooltips: {
          mode: "index"
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false
              },
              ticks: {
                maxRotation: 0,
                autoSkipPadding: 100,
                fontColor: "#B5B5C3"
              },

              stacked: true
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: false,
                borderDash: [4, 8],
                color: "#B5B5C3"
              },
              ticks: {
                fontColor: "#B5B5C3",
                maxTicksLimit: 10,
                beginAtZero: true
              },
              stacked: true
            }
          ]
        },
        onClick: function() {},
        datasets: {
          bar: {
            barPercentage: 0.7
          }
        },
        elements: {
          point: {
            radius: 0
          }
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            boxWidth: 14
          },
          align: "end"
        }
      },
      areaOptions: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false
        },
        tooltips: {
          mode: "index"
        },
        scales: {},
        onClick: function() {},
        datasets: {
          bar: {
            barPercentage: 0.7
          }
        },
        elements: {
          point: {
            radius: 4
          }
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            boxWidth: 14
          },
          align: "end"
        }
      }
    };
  },
  components: { BarChart, AsynCardContainer, AreaChart, DoughnutChart },
  methods: {
    setLegend(html) {
      this.chart.legends = html;
    }
  },
  computed: {}
};
</script>
